import {Component, ViewChild, OnInit, Inject} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import { ApiCommunicationService } from '../services/api_communication/api-communication.service';
import * as moment from 'moment';
import {EventsService} from 'angular4-events';
import {MatOption, MatSelect} from "@angular/material";


@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit {

  @ViewChild('selectStatesInput', {static: false}) selectStatesInput: MatSelect;
  @ViewChild('selectCitiesInput', {static: false}) selectCitiesInput: MatSelect;

  config: any;
  displayedColumns: string[] = ['id', 'name', 'city', 'students_count', 'principal', 'email', 'phone', 'status', 'buttons'];
  dataSource: any;

  loader: any;

  page: number = 0;
  per_page: number = 10;

  filters: string[];
  displayStates: string[];
  displayCities: string[];
  schools_count = 0;

  selected_statuses: string[];
  selected_states: string[];
  selected_cities: string[];
  selected_public: string[];
  selected_type: string[];
  id_key: string;
  keyword: string;
  onlyKeyword: boolean = true;

  search_timeout: any;

  constructor(
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private api: ApiCommunicationService
  ) {}

  ngOnInit() {
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = 'bottom';
    this.config.horizontalPosition = 'center';
    this.config.duration = '4500';

    this.getFilters();
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(CallDialog, {
      disableClose: true,
      width: '1280px',
      data: {school: data}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {

      }
    });
  }

  getFilters(){
    this.loader = true;
    this.api.filters().subscribe(res => {
      this.loader = false;
      if(res['status'] == 'success'){
        this.filters = res['data'];
        this.displayStates = this.filters['state'];

        let selected_statuses = localStorage.getItem('selected_statuses');
        if(selected_statuses){
          this.selected_statuses = JSON.parse(selected_statuses);
        }else{
          this.selected_statuses = ["0", "1", "2", "3", "4", "5", "6"];
        }

        let selected_states = localStorage.getItem('selected_states');
        if(selected_states){
          this.selected_states = JSON.parse(selected_states);
        }else{
          this.selected_states = this.filters['states'];
        }

        let selected_public = localStorage.getItem('selected_public');
        if(selected_public){
          this.selected_public = JSON.parse(selected_public);
        }else{
          this.selected_public = this.filters['public'];
        }

        let selected_type = localStorage.getItem('selected_type');
        if(selected_type){
          this.selected_type = JSON.parse(selected_type);
        }else{
          this.selected_type = this.filters['type'];
        }

        this.getCities();
      }else{
        this.snackbar.open(res['message'], 'Zamknij', this.config);
      }
    });
  }

  saveFilters(){
    localStorage.setItem('selected_statuses', JSON.stringify(this.selected_statuses));
    localStorage.setItem('selected_states', JSON.stringify(this.selected_states));
    localStorage.setItem('selected_cities', JSON.stringify(this.selected_cities));
    localStorage.setItem('selected_public', JSON.stringify(this.selected_public));
    localStorage.setItem('selected_type', JSON.stringify(this.selected_type));
    this.getSchools();
  }

  getCities(){
    this.loader = true;
    localStorage.setItem('selected_cities', '');
    this.api.getCities(this.selected_states).subscribe(res => {
      this.loader = false;
      if(res['status'] == 'success'){
        this.displayCities = res['data'];

        let selected_cities = localStorage.getItem('selected_cities');
        if(selected_cities){
          this.selected_cities = JSON.parse(selected_cities);
        }else{
          this.selected_cities = res['data'];
        }

        this.getSchools();
      }else{
        this.snackbar.open(res['message'], 'Zamknij', this.config);
      }
    });
  }

  searchTimeout(){
    clearTimeout(this.search_timeout);
    this.search_timeout = setTimeout(()=>{
      this.getSchools();
    }, 1000);
  }

  searchCity(value){
    this.displayCities = this.search(value)
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.filters['city'].filter(option => option.toLowerCase().startsWith(filter));
  }

  selectedAllStates(){
    this.selectStatesInput.options.forEach( (item : MatOption) => item.select());
  }

  unselectedAllStates(){
    this.selectStatesInput.options.forEach( (item : MatOption) => item.deselect());
  }

  selectedAllCities(){
    this.selectCitiesInput.options.forEach( (item : MatOption) => item.select());
  }

  unselectedAllCities(){
    this.selectCitiesInput.options.forEach( (item : MatOption) => item.deselect());
  }

  getSchools(){
    this.loader = true;
    let filters = {
      'statuses': this.selected_statuses,
      'cities': this.selected_cities,
      'public': this.selected_public,
      'type': this.selected_type
    }
    this.api.schools(this.page, this.per_page, filters, this.id_key, this.keyword, this.onlyKeyword).subscribe(res => {
      this.loader = false;
      this.dataSource = [];
      this.schools_count = res['count'];
      if(res['status'] == 'success'){
        console.log(res);
        let schools = res['data'];
        for(let school of schools){
          this.dataSource.push({id: school['id'], name: school['name'], principal: school['principal'], phone: school['phone'], address: school['street']+' '+school['street_number'], city: school['city'], state: school['state'], email: school['email'], students_count: school['students_count'], status: school['status'], type: school['type'], notes: ''+school['notes']+'', have_other: ''+school['have_other']+'', other: ''+school['other']+'', other_name: school['other_name'], devices_rating: ''+school['devices_rating']+'', agree: school['agree']});
        }
      }else{
        this.snackbar.open(res['message'], 'Zamknij', this.config);
      }
    });
  }

  handlePage(event){
    this.page = event.pageIndex;
    this.per_page = event.pageSize;
    this.getSchools();
  }
}

@Component({
  selector: 'call-dialog',
  templateUrl: 'call-dialog.html',
  styleUrls: ['./database.component.scss']
})
export class CallDialog implements OnInit {
  calling = false;
  call_time: any = {};
  time_interval;
  school: any;
  config: any;

  calls_loader: any;
  save_loader: any;
  calls: any;

  displayedColumns: string[] = ['id', 'date', 'time', 'caller_id'];
  dataSource: any;

  mails_loader: any;
  mailsList: any;
  campainCounts: any;


  constructor(
    public dialogRef: MatDialogRef<CallDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private snackbar: MatSnackBar,
    private api: ApiCommunicationService
  ) {
    this.school = data.school;
  }

  ngOnInit(){
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = 'bottom';
    this.config.horizontalPosition = 'center';
    this.config.duration = '4500';

    this.getCalls();
    this.getMails();

    this.campainCounts = [0,0];
  }

  startCall() {
    this.calling = true;
    window.location.href = 'tel:' + this.school.phone;
    this.call_time.elapsedTime = '00:00:00';
    const start = moment(new Date());
    this.time_interval = setInterval(() => {
      const elapsedTime = moment(new Date()).diff(start);
      const time = moment.duration(elapsedTime);

      const hrs = ('0' + time.hours()).slice(-2);
      const mins = ('0' + time.minutes()).slice(-2);
      const secs = ('0' + time.seconds()).slice(-2);

      this.call_time.elapsedTime = `${hrs}:${mins}:${secs}`;
    }, 1000);
  }

  endCall() {
    this.calling = false;
    this.call_time.elapsedTime = '00:00:00';
    clearInterval(this.time_interval);
  }

  saveCall(){
    this.save_loader = true;
    this.api.addCall(this.school.id, this.call_time.elapsedTime).subscribe(res => {
      this.save_loader = false;
      if(res['status'] == 'success'){
        this.save_loader = false;
        this.endCall();
        this.snackbar.open('Pomyślnie zapisano czas rozmowy', 'Zamknij', this.config);
        this.getCalls();
      }
    }, err => {
      clearInterval(this.time_interval);
      this.save_loader = false;
      this.snackbar.open('Wystąpił nieznany błąd', 'Zamknij', this.config);
    });
  }

  getCalls(){
    this.calls_loader = true;
    this.api.getCalls(this.school.id).subscribe(res => {
      this.calls_loader = false;
      this.dataSource = [];
      if(res['status'] == 'success'){
        let calls = res['data'];
        for(let call of calls) {
          let date = moment(call['created_at'], 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY');
          this.dataSource.push({
            id: call['id'],
            date: date,
            time: call['connection_time'],
            caller: {name: call['user']['name'], surname: call['user']['surname']}
          });
        }
      }
    });
  }

  getMails(){
    this.mails_loader = true;
    this.api.getMails(this.school.id).subscribe(res => {
      this.mails_loader = false;
      this.mailsList = [];
      if(res['status'] == 'success'){
        for(let mail of res['data']) {
          this.dataSource.push(mail);
          this.campainCounts[mail['campain_id']]++;
        }
        console.log(this.campainCounts);
      }
    });
  }

  sendMail(campain_id){
    this.mails_loader = true;
    this.api.sendMail(this.school.id, campain_id).subscribe(res => {
      this.mails_loader = false;
      this.snackbar.open(res['message'], 'Zamknij', this.config);
      if(res['status'] == 'success'){
        this.getMails();
      }
    });
  }

  changeAgree(event, type){
    if(this.school.agree != 4) {
      let phone_agree = 0;
      let email_agree = 0;

      if(this.school.agree == 1 || this.school.agree == 3){
        phone_agree = 1;
      }

      if(this.school.agree == 2 || this.school.agree == 3){
        email_agree = 1;
      }

      if(event.checked == true){
        if(type == 1){
          phone_agree = 1;
        }else if(type == 2){
          email_agree = 1;
        }
      }else{
        if(type == 1){
          phone_agree = 0;
        }else if(type == 2){
          email_agree = 0;
        }
      }

      let status = 0;
      if(phone_agree == 1 && email_agree == 1){
        status = 3;
      }else if(phone_agree == 1){
        status = 1;
      }else if(email_agree == 1){
        status = 2;
      }else{
        status = 0;
      }

      this.school.agree = status;

      this.saveInfo();
    }
  }

  saveInfo(){
    this.save_loader = true;
    let count = Object.keys(this.school).length;
    let i = 0;
    for(let school_value in this.school){
      if(this.school[school_value] == "null"){
        this.school[school_value] = null;
      }
      i++;
      if(i == count){
        this.api.saveSchool(this.school).subscribe(res => {
          this.save_loader = false;
        });
      }
    }
  }

}
