import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EventsService } from 'angular4-events';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'callsystem';

  token: string = null;

  user: any = {
    name: '',
    surname: '',
    username: '',
    token: '',
    perm: ''
  }

  config: any;

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private events: EventsService
  ) {}

  ngOnInit() {
    this.getUserData();

    this.events.subscribe('logged').subscribe((from) => {
      this.getUserData();
    });
  }

  getUserData(){
    this.token = localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['/login']);
    }else{
      this.user.name = localStorage.getItem('name');
      this.user.surname = localStorage.getItem('surname');
      this.user.username = localStorage.getItem('username');
      this.user.token = localStorage.getItem('token');
      this.user.perm = localStorage.getItem('perm');
    }
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['/login']);
    this.token=null;
    this.snackbar.open('Poprawnie wylogowano!', 'Close', this.config);
  }

  // this.router.navigate(['/login']);
}
