import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import { EventsService } from 'angular4-events';
import { ApiCommunicationService } from '../services/api_communication/api-communication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  token: string = null;

  login: any;
  password: any;

  loader: any = false;
  config: any;

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private events: EventsService,
    private api: ApiCommunicationService
  ) { }

  ngOnInit() {
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = 'bottom';
    this.config.horizontalPosition = 'center';
    this.config.duration = '4500';

    this.token = localStorage.getItem('token');
    if(this.token){
      this.router.navigate(['/dashboard']);
    }
  }


  authorization(){
    this.loader = true;

    if(!this.login || !this.password){
      this.snackbar.open('Wypełnij wszystkie pola', 'Close', this.config);
      this.loader = false;
    }else{
      this.api.login(this.login, this.password).subscribe(res => {
        this.loader = false;
        if(res['status'] == 'success'){
          localStorage.setItem('username', res['data']['username']);
          localStorage.setItem('name', res['data']['name']);
          localStorage.setItem('surname', res['data']['surname']);
          localStorage.setItem('token', res['data']['token']);
          localStorage.setItem('perm', res['data']['perm']);

          this.events.publish('logged', 'logged');
          this.router.navigate(['/dashboard']);

        }else{
          this.snackbar.open(res['message'], 'Zamknij', this.config);
        }
      });
    }
  }

}
