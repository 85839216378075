import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventsService } from 'angular4-events';

@Injectable({
  providedIn: 'root'
})
export class ApiCommunicationService {
  api_url: string;
  token: string;

  constructor(
    private http: HttpClient,
    private events: EventsService
  ) {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){
      this.api_url = "http://localhost:8000";
    }else{
      this.api_url = "https://callsystem.globalsoft.com.pl/api/public";
    }
    this.getToken();
    this.events.subscribe('logged').subscribe((from) => {
      this.getToken();
    });
  }

  getToken(){
    this.token = localStorage.getItem('token');
  }

  login(username, password){
    return this.http.post(this.api_url+'/login',
      {
        "username": username,
        "password": password
      },
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'json',
      });
  }

  schools(page, per_page, filters, id_key, keyword, onlyKeyword){
    return this.http.post(this.api_url+'/get_schools',
      {
        "page": page,
        "per_page": per_page,
        "filters": filters,
        "id_key": id_key,
        "keyword": keyword,
        "onlyKeyword": onlyKeyword
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

  saveSchool(school){
    return this.http.post(this.api_url+'/save_school',
      {
        "school": school
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

  filters(){
    return this.http.get(this.api_url+'/schools_filters_list',
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

  getCities(selectedStates){
    return this.http.post(this.api_url+'/get_schools_city_list',
      {
        selectedStates: selectedStates
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

  addCall(school_id, connection_time){
    return this.http.post(this.api_url+'/calls',
      {
        school_id: school_id,
        connection_time: connection_time
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

  getCalls(school_id){
    return this.http.get(this.api_url+'/get_calls_by_school?school_id='+school_id,
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

  getMails(school_id){
    return this.http.get(this.api_url+'/get_mails_by_school?school_id='+school_id,
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

  sendMail(school_id, campain_id){
    return this.http.post(this.api_url+'/mails', {
        school_id: school_id,
        campain_id: campain_id
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': this.token },
        responseType: 'json',
      });
  }

}
